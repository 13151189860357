<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        <vd-header :app="app" />

        <b-row class="border py-3">
          <b-col cols="12" xl="4">
            <strong>Velkommen til {{ AppTypeLabel.get(app) }}</strong>
            <p class="mb-0">{{ AppTypeLabel.get(app) }} er et system til efterbehandling</p>
            <p class="mb-0">af trafiktællinger.</p>
            <p class="mb-0">Anvendes af Vejdirektoratet</p>
            <p>og udvalgte kommuner.</p>

            <img src="@shared/assets/mastralogo.jpg" class="mastra-logo pb-3 w-100" alt="mastra logo" />

            <p class="font-weight-bold mb-0">Links</p>
            <p class="mb-0">
              <a title="Mastra datakontrol" href="/ords/mastra/osa.mdk.mdk_interface_per" target="_blank">Mastra Datakontrol</a>
            </p>
            <p class="mb-0">
                <a title="Mastra datakontrol Quick" href="/ords/mastra/osa.mdk.mdk_interface_quick1" target="_blank">Mastra Datakontrol Quick</a>
            </p>
            <p class="mb-0">
              <a title="Mastra Nøgletalsdatabase" href="noegletal.html" target="_blank">Mastra Nøgletalsdatabase</a>
            </p>
            <p class="mb-0">
              (sidst opdateret <span id="noegletal_opdateret"></span>)
            </p>
            <p>
              <a title="kMastra" href="kmastra.html">kMastra</a>
            </p>
          </b-col>
          <b-col cols="12" xl="4">
            <p class="font-weight-bold mb-0">
              Vejledninger og dokumentation
            </p>
            <p class="mb-0">
              Vi har samlet relevante vejledninger og dokumenter til alle Mastra systemerne. Med nedenstående link kommer du til et indeks over vejledninger og dokumenter.
            </p>
            <p class="mb-0">
              <a href="../../mastradok/mastradok.html?v=6" target="_blank">Vejledninger / dokumentation</a>
            </p>

            <vd-newsviewer class="mt-3" :app='app'/>            
          </b-col>
          <b-col cols="12" xl="4">
            <p class="font-weight-bold mb-0">
              Kontakt
            </p>
            <p class="mb-0">
              Har du et problem/spørgsmål af trafikfaglig karakter, om brugen af {{ AppTypeLabel.get(app) }}, eller er din henvendelse af mere teknisk karakter, så kontakt Mastra-Supportfunktionen i Trafikstatistikafdelingen på følgende link:
            </p>
            <p class="mb-0">
              <a href="#" onclick="javascript: window.open('./support.html','suppWin','height=350,width=700')">Opret supportsag</a>
            </p>

            <img src="@shared/assets/foto9.jpg" class="w-100"/>
          </b-col>
        </b-row>

        <vd-footer />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vdHeader from '@shared/components/layout/vdHeader'
import vdFooter from '@shared/components/layout/vdFooter'
import vdNewsviewer from '@shared/components/vdNewsviewer';
import { AppTypeEnum, AppTypeLabel } from '@shared/enums/appTypeEnum';
import { makeRequest, gemBrugerInfo, GetCookie } from '@shared/utils/AjaxUtil.js';

export default {
  name: 'Mastra',
  components: {
    vdHeader,
    vdFooter,
    vdNewsviewer
  },
  data: function() {
    console.log()

    return {
      app: AppTypeEnum.iMastra,
      AppTypeLabel
    }
  },
  methods: {
    initPage() {
      makeRequest(this.$variables.OrdsApi.QuickRequest, "noegletal_opdateret", "f1", 3);
      if(GetCookie('session_id') == null){
        makeRequest(this.$variables.OrdsApi.QuickRequest, "session_id","f1",6);
      }else{
        makeRequest(this.$variables.OrdsApi.QuickRequest, "password_age_ny", "f1", 31);
      }
      gemBrugerInfo();
    }
  },
  mounted() {
      this.initPage();
  }
}
</script>

<style lang="scss">
    @import "@shared/styles/mastra.scss";

    .mastra-logo {
      width: 250px;
    }
</style>